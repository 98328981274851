<template>
    <div class="manage">
        <h5header :name="$t('sign.desc63')" :right="false" />
        <div class="manage-con">
            <div class="manage-link">
                <img src="../../public/images/new/lefticon3.png" alt="" @click="$router.go(-1)" /> <span>{{
                    $t('manage.desc40') }}</span>
            </div>

            <div class="modal-con">
                <div class="modal-title">{{ $t('sign.desc64', { n1: typename }) }}</div>
                <div class="modal-name">{{ $t('sign.desc65') }}{{ sourceEmail }}</div>
                <div class="modal-desc">{{ $t('sign.desc17') }}</div>
                <div class="modal-input">
                    <input type="text" :placeholder="$t('sign.desc18')" v-model="name"
                        onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" />
                </div>
                <div class="modal-tips">{{ $t('sign.desc19') }}</div>
                <div class="modal-desc" v-if="type == 1">{{ $t('sign.desc69') }}</div>
                <div class="modal-input flex" v-if="type == 1">
                    <input type="text" :placeholder="$t('sign.desc21')" v-model="email"
                        onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" />
                </div>
                <div class="modal-desc" v-if="type == 1">{{ $t('sign.desc24') }}</div>
                <div class="modal-input flex" v-if="type == 1">
                    <input type="text" :placeholder="$t('sign.desc25')" v-model="code" maxlength="6"
                        onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" />
                    <p @click="handlerSend">{{ sendText }}</p>
                </div>
                <div class="modal-btn flexcenter" @click="handlerSubmit" v-if="type == 1" v-loading="loading">{{
                    $t('home.desc20') }}</div>
                <div class="modal-btn flexcenter" @click="handlerSubmit1" v-else v-loading="loading">{{ $t('home.desc20') }}
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import h5header from '@/componets/h5header.vue';
import bus from '@/utils/bus'
export default {
    inject: ['reload'],
    components: {
        h5header
    },
    data() {
        return {
            name: '',
            email: '',
            loading: '',
            typename: 'Satoshi AI',
            sendText: this.$t('sign.desc26'),
            code: '',
            issend: true,
            sourceEmail: '',
            type: 0
        }
    },
    mounted() {
        if (this.$route.query.type == 1) {
            this.typename = 'HyperNation';
            this.type = this.$route.query.type;
        }
        this.sourceEmail = localStorage.getItem('email')
    },
    methods: {
        handlerSend() {
            let reg = /^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z0-9]{2,5}$/, _this = this;
            if (this.email == '') {
                this.$message.error(this.$t('sign.desc21'));
                return;
            }
            if (!reg.test(this.email)) {
                this.$message.error(this.$t('sign.desc35'));
                return;
            }
            if (this.issend) {
                this.issend = false;

                this.$post(this.URL.login.sendverify, {
                    accountType: 1,
                    account: this.email,
                    type: 9
                }).then(res => {
                    if (res.code == 0) {
                        this.$message.success(this.$t('sign.desc37'));
                        let num = 60
                        this.timeNull = setInterval(() => {
                            num--;
                            if (num < 1) {
                                clearInterval(_this.timeNull);
                                _this.issend = true;
                                _this.sendText = _this.$t('sign.desc26')
                            } else {
                                this.sendText = `${num} S`
                            }
                        }, 1000)
                    } else {
                        this.issend = true;
                    }
                })

            }
        },
        handlerSubmit() {
            let reg = /^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z0-9]{2,5}$/;
            if (this.name == '') {
                this.$message.error(this.$t('sign.desc33'));
                return;
            }
            if (this.email == '') {
                this.$message.error(this.$t('sign.desc21'));
                return;
            }
            if (!reg.test(this.email)) {
                this.$message.error(this.$t('sign.desc35'));
                return;
            }
            if(this.loading){
                return;
            }
            this.loading = true;
            this.$post(this.URL.login.bindSal, {
                account: this.name,
                email: this.email,
                code: this.code
            }).then(res => {
              
                if (res.code == 0) {
                    this.$message.success(this.$t('sign.desc66'))
                    this.getinfo()
                } else {
                    this.loading = false;
                    this.$message.error(res.message)
                }
            })
        },
        handlerSubmit1() {
            if (this.name == '') {
                this.$message.error(this.$t('sign.desc33'));
                return;
            }
            this.loading = true;
            this.$post(this.URL.member.update, {
                account: this.name
            }).then(res => {
                this.loading = false;
                if (res.code == 0) {
                    this.$message.success(this.$t('sign.desc66'))
                    this.getinfo()
                } else {
                    this.$message.error(res.message)
                }
            })

        },
        getinfo() {
            this.$post(this.URL.member.info, {
            }).then((ress) => {
                if (ress.code == 0) {

                    this.$store.commit('SET_USERDATA', ress.data)
                    this.$store.commit('SET_LOGIN', true)
                    if(localStorage.getItem('ismobile') == 0){
                        this.$router.push('/home');
                    }else{
                        this.$router.push('/stake');
                    }
                    bus.emit('onLogin')
                    this.reload();
                    // setTimeout(() => {
                    //     location.reload();
                    // }, 1000);
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
.manage {
    width: 100%;
    min-height: 90vh;
    padding: 136px 0 80px;
    background: url('../../public/images/new/landbg.png') center no-repeat;
    background-size: 100% 100%;

    .manage-con {
        width: 480px;
        margin: 0 auto;
        position: relative;

        .manage-link {
            display: flex;
            margin-bottom: 20px;
            font-size: 14px;
            color: rgba(255, 255, 255, 0.6);
            line-height: 14px;

            img {
                width: 12px;
                height: 14px;
                margin: 0 8px;
            }

            span {
                color: #fff;
            }
        }

        .modal-con {
            padding: 32px;
            background: rgba(24, 24, 24, 0.8);
            border-radius: 12px;

            .modal-title {
                padding-bottom: 16px;
                font-size: 20px;
                color: #FFFFFF;
            }

            .modal-name {
                font-size: 16px;
                color: rgba(255, 255, 255, 0.6);
            }

            .modal-desc {
                margin: 16px 0 12px;
                font-size: 16px;
                color: #FFFFFF;
            }

            .modal-input {
                padding: 10px 16px;
                border: 1px solid #393939;
                box-sizing: border-box;

                input {
                    flex: 1;
                    height: 32px;
                    font-size: 14px;
                    color: #fff;

                    &::placeholder {
                        color: #5F5F5F;
                    }
                }

                p {
                    font-size: 14px;
                    color: #C9FA5B;
                    line-height: 32px;
                    cursor: pointer;
                }
            }

            .modal-tips {
                margin: 16px 0;
                font-size: 14px;
                color: #EB4343;
            }

            .modal-btn {
                margin-top: 40px;
                height: 55px;
                background: url('../../public/images/new/btnbg21.png') center no-repeat;
                background-size: 100% 100%;
                font-size: 18px;
                color: #000000;
                cursor: pointer;
            }

        }






    }
}


@keyframes ball {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width:767px) {
    .manage {
        padding: 56px 0;
        background: #000000;

        .manage-con {
            width: calc(100vw - 8px);

            .manage-link {
                display: none;
            }

            .modal-con {
                padding: 20px 16px;

                .modal-btn {
                    margin-top: 20px;
                }
            }

        }
    }

}</style>